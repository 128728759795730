.CheckoutProcess{
    padding-top: 115px;
    .checktitle{
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #111111;

    }
    .CheckoutSubTitle{
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #111111;

    }
}
