//font size 
$extraSmallfont: 12px;
$smallfont: 16px;
$mediumFont: 20px;
$largefont: 25px;
$extraLargeFont: 32px;


//font weight
$overWeight: 700;
$belowOverWeight: 600;
$meduim: 500;
$aboveWeight: 400;
$belowWeight: 300;


//line hieght
$extraLargeLineheight: 44px;
$largeLineHeight: 33px;

