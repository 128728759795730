.searchContainer{
    .searchDetail{
        position: absolute;
        //left: 28%;
        top:37px;
        background: #fff;
        border: 1px solid #E0E0E0;
        height: 382px;
        overflow-y: scroll;
        overflow-x: hidden;
        //overflow: auto;
        p{
            color: #999999;
            font-weight: 700;
            font-size: 12px;
        }
        ul{
            list-style-type : none;
            padding: unset;
            // overflow-y: auto;
            // height: 332px;

            li{
                padding-bottom: 13px;
            }
        }
        ul::-webkit-scrollbar {
            display: none;
        }
        .matcingbarnd{
            li{
                font-weight: 600;
                font-size: 11px;
                line-height: 16px;
                color: #333333;
            }
        }
        .searchDetailList{
            //border-right: 1px solid #E0E0E0;
            padding: 8px 14px 8px 22px;

            
            
            img{
                width: 100%;
                border: 1px solid #E0E0E0
            }
            .searchTitle{
                color: #085073;
                font-weight: 700;
                font-size: 10px;
                line-height: 14px;
            }
            div span{
                color: #4F4F4F;
                font-weight: 400;
                font-size: 11px;
            }
        } 

        
        

    }
    .searchDetail::-webkit-scrollbar {
        width: 3px;
        height: 3px;
    }

    .searchDetail::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 15px #c90101;
    }
    
    @media screen and (max-width: 1024px) {
        
    }
    
    @media screen and (max-width: 991px) {
        .searchDetail{
            top: 79px;
            left: -49px;
            max-width: unset;
            width: 250%;
            height: auto;
            z-index: 9999;

            img{
                max-width: 40px;
            }
            .searchDetailList{
                ul{
                    height:400px;
                    overflow-y: scroll;
                }
            }
        }
    }
    @media screen and (max-width: 970px) {
        
    }
    @media screen and (max-width: 768px) {
        
    }

   
    
}


