.categoryItems{
    padding: 15px 10px;
    width: 94%;
    height: 259px !important;

    background: #FFFFFF;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px !important;
    border: none !important;
    .sunglasses{
        width: 100%;
        height: 96px;
        object-fit: contain;
        background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
        
    }
    .catDealPrice{
        color: #040079;
        margin: unset;
        padding-top: 8px;
        padding-bottom: 6px;
    }
    .categoryText{
        margin-top: 8px;
        height: 29px;
        font-weight: 400;
        font-size: 11px;
        line-height: 15px;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
                line-clamp: 2; 
        -webkit-box-orient: vertical;
    }
    .debitCard{
        border-radius: 4px;
        background: #00A8F0;
        text-align: center;
        margin-top: 8px;
        padding: 4px;
        font-weight: 600;
        font-size: 10px;
        line-height: 14px;
        color: #FFFFFF;
    }
    .view_all {
        color: #000000 !important;
    }
    @media (min-width: 991px) {
        height: 350px !important;
      
        .categoryText{
            height: 38px;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #333333;
            margin-top: 12px;
        }
        .sunglasses{
            width: 100%;
            height: 154px;
        }
        .debitCard{
            font-weight: 600;
            font-size: 11px;
            line-height: 18px;
            padding: 3px 6px;
            margin-top: 12px;
        }
        .catDealPrice{
            padding-bottom: 8px;
            font-size: 20px;
        }
        .dealPercentage{
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
        }
    }
}
