$cardPadding: 16px;


//$midRaduis: 12px;
$MinRadius : 4px;



$fitContent: fit-content;


$extraLargeSpacing: 32px;
$largeSpacing: 24px;



$SmallSpacing: 12px;

$extraSmallSpacing: 10px;





 