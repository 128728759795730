.footerMobile{
    position: fixed;
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 60px;
    background-color: #EBF8FF;
    
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    padding: 13px 0px 10px;
    z-index: 2000;

    .footerMobIcon{
        font-size: 20px;
    }

    & span{
        padding: 0px 3px;
    }
    .footerLink{
        color: #333333;
        text-decoration-line: none;
    }
    .cartSection .badge1{
        right: 14%;
        top: 2px;
        bottom: unset;
    }
    .footerCartCon{
        display: flex;
    flex-direction: column;
    align-items: center;
    }
    

    
}