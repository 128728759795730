.FAQs{
    font-family: 'Poppins',sans-serif;
    .AccordionItem{
        border: 1px solid  #E0E0E0 !important;
        border-top-left-radius: calc(0.375rem - 1px) !important;
        border-top-right-radius: calc(0.375rem - 1px) !important;
        margin-bottom: 25px;
        //padding: 10px 15px 10px;
        .accordion-button{
            background: border-box;
            box-shadow: none;
            border-bottom: 1px solid rgba(0,0,0,.125);
            padding: 1%;
        }
        
    }
    h4{
        font-size: 14px;
        font-weight: 700;
        color: #000;
        
    }
    .accordiondefault{
        padding: 10px 15px 10px;
        box-shadow: none;
        border-color: rgba(0,0,0,.125);

    }
    p{
        font-size: 14px;
        line-height: 1.42857143;
        color: #333;
    }
    li{
        font-size: 14px;
        line-height: 1.42857143;
        color: #333;
    }
    @media screen and (max-width: 375px){
        /* .FAQs{
            padding-left: 2%;
        } */
        .AccordionItem{
            /* margin-left: 3%;
            margin-right: 0%; */
        }
    }
        
    
}