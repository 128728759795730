.navigation{
    display: flex;
    color: #000000;
    font-weight: 600;
    font-size: 14px;
    
    //padding-left: 4% !important;
    border-bottom: 1px solid #f1f1f1;
    padding: unset;
    /* .pharmacy-switch{
        position: absolute;
        top: 64px;
        right: 17px;
        z-index: 9999;
    } */
    .gifImage{
        width: 160px;
        margin: 5px;
        height: 30px;
    }
    a{
        text-decoration: none;
        color: #000;
    }

    .left-product-cat {
        position: relative;
        width: 100%;
        height: 40px;
        min-width: 130px
    }
    .category-heading {
       /*  overflow: hidden; */
        /* border-right: 1px solid #f1f1f1; */
        color: #024284;
        font-size: 16px;
        font-weight: 600;
        line-height: 40px;
        padding-left: 0px;
        width: 100%;
        
    }
    .cat-align {
        
        /* border-left: 1px solid #f1f1f1; */
        /* overflow: hidden; */
        text-align: center;
        width: 100%;
        transition: all .3s;
        max-width: 18%;
    }
    .category-menu-list{
        position: absolute;
        width: auto;
        z-index: 999;
        font-weight: 400;
        font-size: 12px;
        color: #000000;
        border-bottom: 1px solid #ddd;
       // border-left: 4px solid #083ea2;
        border-right: 0;
        padding: 12px 0;
        //background: #e9f0fc;
        box-shadow: 0px 3px 8px 0 rgb(0 0 0 / 18%);
    }
    
    .scroll{
        max-height: 526px;
        width: 280px;
        overflow: auto;
    }
    .scroll::-webkit-scrollbar {
       
        width: 2.5px;
    }
    .scroll::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 15px #c90101;
    }
    .subCategoryUl{
        max-height: 517px;
        overflow: auto;
        width: 248px;
    }
    .subCategoryUl::-webkit-scrollbar {
        width: 2.5px;
    }
    .subCategoryUl::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 15px #c90101;
    }
    .subsubcategoryul{
        max-height: 214px;
        overflow: auto;
    }
    .subsubcategoryul::-webkit-scrollbar{
        width: 2.5px;
    }
    .subsubcategoryul::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 15px #c90101;
    }
    
    .brandScroll{
        max-height: 284px;
        overflow: auto;
    }
    .brandScroll::-webkit-scrollbar{
        width: 2.5px;
    }
    .brandScroll::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 15px #c90101;
    }
    .nav-menu-list{
        left: 100%;
        position: absolute;
        text-align: left;
        padding: 16px 0px;
        top: 0;
        width: 250px;
        z-index: 9999999;
        background: #fff;
        height: 550px;
    }
    /* .nav-menu-list::-webkit-scrollbar{
        background: transparent;
        width: 0px;
    } */
    .cat-left-drop-menu{
        left: 189%;
        position: absolute;
        text-align: left;
        top: 0;
        width: 250px;
        z-index: 9999999;
        height: 550px;
        border-left: 0.6px solid rgba(0, 0, 0, 0.12);
    }
    /* .cat-left-drop-menu::-webkit-scrollbar{
        background: transparent;
        width: 0px;
    } */
    .subsubcategoryul{

    }

    .category-title{
        padding-left: 12px;
        color: #000000;
        font-weight: 700;
    }
    
    .mega-catagory .category-title{
        padding-top: 16px;
    }
    & ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    
    & li:hover {
        /* background-color: #FFFFFF; */
        cursor: pointer;
    }
    .cat-left-drop-menu {
        background: #fff;
    }
    .cat-left-drop-menu .layer-one .cat-left-drop-menu-left .scroll {
        overflow: auto;
        max-height: 480px;
        padding-left: 0;
        margin-bottom: 0;
    }
    .category-menu-list {

        & li{
            padding: 8px 12px;
        }
        li:hover {
            background-color: #FFFFFF;
        }
        a{
            text-decoration: none;
            color: #000;
        }
    }
    
    //try end
    & .navigation_ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        & li{
            float: left;

            & a{
                display: block;
                color: #000;
                text-align: center;
                padding: 8px 15px;
                text-decoration: none;
            }
        }  
        & .nav_dropdown{
            padding: 8px 0px;
        }  
    }

    .category_btn{
        border-radius: unset;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #0D69E0;
        border: none;
        color: #000000;
        font-weight: 600;
        font-size: 14px;
        width: 100%;
        /* max-width: 100%; */
        height: 100%;
        padding-bottom: 4px;
    }
    .dropdown-toggle::after {
        content:none;
        border:none;
        color: #000000;
    }
    button:not(:disabled):hover{
        color: #000000;
    }
    
    

    @media screen and (min-width: 2560px) {
        
        //padding-left: 5% !important;
    }
    @media screen and (min-width: 1440px) {
        
        //padding-left: 6% !important;
    }

    @media screen and (max-width: 1024px) {
        
        
    }
    @media screen and (max-width: 991px) {
        padding-right: unset !important;
        padding-left: unset !important;
        font-weight: 600;
        font-size: 12px;
        height: 32px;
        
        .left-product-cat{
            height: 32px;
            //right: 4px;
            min-width: 39px;
        }
        .navigation_ul{
            white-space: nowrap;
            overflow-y: scroll;
            display: flex;
            -ms-overflow-style: none; 
            scrollbar-width: none;  

            li a{
                padding: 4px 16px;
            }
        }
        .navigation_ul::-webkit-scrollbar {
            display: none;
        }
        
        .category_btn{
            height: 32px;
        }
        .cancelMenu{
            color: #000000;
        }

        
        
    }
    @media screen and (max-width: 768px) {
        
    }
    @media screen and (max-width: 425px) {
        
    }
    @media screen and (max-width: 375px) {
        
    }
}