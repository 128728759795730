.noresult-found{
    width: 100%;
    background-color: #f5f5f5;
    display: inline-block;
    // padding: 136px 0 100px;
    .no_result_msg{
        font-size: 32px;
        font-weight: 700;
        font-family: 'Gilroy-Black';
        margin-right: 75px;
        color: #333;
        padding: 15px 0;
    }
    .noresult-inner .button-link:hover{
        background-color: #024284;
    }
    .noresult-inner .button-link{
        margin-right: 86px;
        padding: 0 50px;
        width: 25%;
        background: #c90101;
        text-decoration: none;
    border: none;
    border-radius: 0;
    box-shadow: none;
    color: #fff;
    display: inline-block;
    font-size: 13px;
    font-weight: 700;
    height: 45px;
    line-height: 45px;
    padding: 0 16px;
    text-shadow: none;
    text-transform: uppercase;
    transition: all .3s ease 0s;
    white-space: nowrap;


    }
    .button-link:hover, .button:hover, .checkout-button{
        background-color: #c90101;
        text-shadow: none;
        color: #fff;
    }
    button:active, button:hover{
        outline: 0;
        text-decoration: none;
    }

}