.OurBlog{
    // font-family: helvetica neue,Helvetica,Arial,sans-serif;
    padding-top: 3%;
    img{
        width: 100%;
    }
    
    .Announcement{
        font-size: 20px;
        line-height: 30px;
        color: #333333;
        
    }
    .linkimg{
        width: 100%;
        //height: 170px;
        border-radius: 8px;
       
       // background-image: url(/public/Assets/parseimg.webp);
        
    }
    .backcolor{
        background: #F2F2F2;
        border-radius: 12px;
        margin: 0;
        width: 100%;
        padding: 24px;
        padding-left: 10px;


    }
    
    .Announcement:hover{
        color: #c90101;
    }
    .date{
        
        border-top: 1px solid #4F4F4F;
        border-bottom: 1px solid #4F4F4F;
        padding-top: 2%;
        margin: 4% 0%;
        // padding: 7px 0px;
        //margin-top: 2%;
        //padding: 7px 0px;
        //text-align: end;
    }
    .admin:hover{
        color: #c90101;
    }
    .Ourshopeecom{
        color: #c90101;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: #4F4F4F;
       // padding-top: 4%;

    }

    .verticallion{
        border-left: 1px solid #e5e5e5;
        margin-left: 5%;
        height: 100%;

    }
    .Categories{
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        color: #333333;
        //padding-left: 8%;
        //border: 1px solid #BDBDBD;
       

    }
    .Categorieslist{
        //padding-left: 6%;
    }
    .list:hover{
        color: #c90101;
    }
    .list{
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #4F4F4F;

        

    }
    .Announcementsmall:hover{
        color: #c90101;
    }
    .Announcementsmall{
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 7px;
        color: #333333;
        
    }
    .smalllinkimg{
        width: 100%;
        border-radius: 4px;
        height: 56px;
        //height: 44%;
        //margin-top: 14%;
        
    }
    .smalldate{
        //padding-left: 38%;
        color: #909295;
        font-size: 11px;
    }
    a:hover {
        color: #c90101;
    }
    a {
        transition: all .3s ease 0s;
        color: #4F4F4F;
    }
    .Recentsdate{
        //padding-left: 14%;
    }
    .listleft{
        padding-left: 14%;
        
    }
    .left{
        float: right;
        text-align: end;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: #4F4F4F;
    }
    @media screen and (max-width: 991px) {
        .linkimg{
            /* margin-left: 1%;
            padding-top: 1%; */
            
        }
        .screennine{
            width: 100%;
           
           
        }
        .smalllinkimg{
            width: 100%;
            height: 56px;
            margin-top: 4%;
            
        }
        .date{
            margin-top: 2%;
        }
        .left{
            float: left;
        }
        .listleft{
            padding-left: 0%;
            padding-top: 14%;
            
        }
        .Recentsdate{
            padding-top: 2%;
        }
        .verticallion{
            border-left: none;
    
        }
        .Business{
            padding-top: 8%;
        }
       
       
    }
    

    @media screen and (max-width: 768px){
        .postedby{
            //padding-left: 56%;
        }

    }
    
}