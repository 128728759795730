.PrivacyPloicy{
    font-family: 'Poppins',sans-serif;
    font-style: normal;
    //padding-left: 5%;
    padding-top: 3%;
    .privcyparag{
        font-size: 12;
    }
    .capitaltitle{
       /*  padding-right: 7px;
        font-size: 17px;
        font-weight: 600;
        color: #000;
        margin: 20px 0 15px; */
        font-size: 14px;
        color: #000;
        margin: 20px 0 15px;
        font-weight: 600;
    }
    .ulimg{
        list-style: none;
        padding-left: 0;
    }
    .ulimg li {
        position: relative;
        padding-left: 30px;
    }
    .ulimg li:before {
        content: '';
        width: 24px;
        height: 24px;
        position: absolute;
        background-image: url('../../public/Assets/hand-point-right.png');
        //background-size: cover;
        //background-position: center;
        left: 0;
        //top: 0;
       
    }
    li{
        font-size: 12px;
        color: #333;
        line-height: 24px;
    }
    p{
        font-size: 12px;
        line-height: 24px;
        color: #666;
        //padding-top: 1%;
    }
}