

$navbarBackgroundColor: linear-gradient(90deg, #7D23C7 0%, #320D9A 100%);
$clearanceBackgroundColor: linear-gradient(270deg, #F5BB44 0%, #EBDB4B 100%);

$limeBackgroundColor: #FDFFEB;
$skyBackgroundColor: #D7F2FF;
$yellowBackgroundColor: #FBA936;
$saverBackgroundColor: #FFE4D7;
$dealBackgroundColor: #D7D7FF;
$orangeBackground : #FD7900;
$titleColor: #403f41;

//borderColor
$borderColorgrey: rgba(0, 0, 0, 0.08);


//color css
$blueColor : #0D69E0;
$prodNameColor: #333333;

$priceColor: #040079;
$price2Color: #EB5757;
$textWhite: #ffffff;
$oldProceColor: #4F4F4F;

