/* Define the shaking animation for the cart button */
@keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-8px); }
    50% { transform: translateX(8px); }
    75% { transform: translateX(-8px); }
    100% { transform: translateX(0); }
  }
  
  /* Apply the shaking animation to the element with the 'shake-effect' class */
  .shake-effect {
    animation:shake 0.15s;
  }