.card_border{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px;
    gap: 16px;
    width: 432px;
    height: 222px;
    background: #FFE8D9;
    border-radius: 8px;
}