.wtl-slidertop{
    .carousel-control-prev,.carousel-control-next{
        display:none;
    }     
}
.wtl-slidertop .img{ width:50% !important;}
.wtl-cnt {
    padding: 18px 25px 15px;
    background: #3a3096;
    border-radius: 30px 30px 0px 0px;
    width: 76%;
    margin: 0 auto;
    text-align: center;

        h2{
            font-size: 36px;
            margin: 0 0 10px;
            color: #fff; font-weight:bold;
            letter-spacing:1px;
        }

        h3{
            font-size: 18px;
            padding: 6px 110px;
            line-height: 30px;
            margin: 10px 0;
            color: #fff;
            letter-spacing: 1px;
        }

        p {
            font-size: 13px;
            color: #fff;
            font-weight: 500;
        }
}

.wtl-slidertop .carousel-item img.d-block.w-100 {
        width: 60% !important;
        margin: 0 auto;
}
.wtl-artist-line{
        padding: 35px 0 50px;
        position: relative;
        background: url(https://www.ourshopee.com/images/wtl/artist-bg.jpg);
}
.wtl-artist-line{
    h2{
        padding: 0 20% 1%;
        text-align: center;
        color: #fff;
        line-height: 30px;
        font-size: 26px;
        font-family:poppins, sans-serif !important;
    }
}
.wtl-a-iner {
    padding: 0 10%;
}

p.teamborder-style {
        border-style: solid;
        border-width: 1px 1px 1px 1px;
        border-color: #FF6600;
        border-radius: 2px 2px 2px 2px;
        margin:0;
        width:92%;
}
.wtl-team-line {
        padding:35px 0 50px;
        position:relative;
        background-color:#d0db51;
}
.wtl-a-iner h2 {
    text-align:center;
    font-weight:bold;
}